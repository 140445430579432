import React, { FC } from 'react';
import styled from '@emotion/styled';

import { Text } from './text';

interface IntroductionComp {
  onClick: () => void;
}

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding-bottom: 48px;
  position: absolute;
  right: 0;
  top: 0;
`;

const TextWrapper = styled.div`
  max-width: 453px;
`;

const Introduction: FC<IntroductionComp> = ({ onClick }) => (
  <Container onClick={onClick}>
    <TextWrapper>
      <Text type="header-3" style={{ paddingBottom: '16px' }}>Tap to start writing</Text>
      <Text type="caption">Write your signature using your finger - you have as many attempts as needed.</Text>
      <Text type="caption">Your signature will be displayed on certificates you sign.</Text>
    </TextWrapper>
  </Container>
);

export default Introduction;
