import React, { FC } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 20px;
  position: fixed;
  right: 0;
  top: 0;
`;

const FullScreenContainer: FC = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default FullScreenContainer;
