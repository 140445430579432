import React, { useState, useEffect } from 'react';
import { Icon, DigitalSignatureTheme } from '@diwala/killer-shrew';
import jwtDecode from 'jwt-decode';
import { setErrorUserId } from 'services/error/error-logger';

import FullScreenContainer from 'components/full-screen-container';
import { Text } from 'components/text';

import SignatureModule from './signature';

const _isPortrait = (orientation: number | string) => orientation === 0 || orientation === 180;

const Signature = () => {
  const [isPortrait, setPortrait] = useState(_isPortrait(window.orientation));
  const urlParams = new URLSearchParams(window.location.search);
  const session = urlParams.get('session');

  useEffect(() => {
    const handleResize = () => {
      setPortrait(_isPortrait(window.orientation));
    };

    window.addEventListener('orientationchange', handleResize);
    return () => window.removeEventListener('orientationchange', handleResize);
  }, []);

  if (isPortrait) {
    return (
      <FullScreenContainer>
        <Text type="header-3" style={{ paddingBottom: '16px' }}>Rotate your phone!</Text>
        <Text type="caption">Please use your phone in landscape mode to use the signing feature.</Text>
      </FullScreenContainer>
    );
  }

  if (session) {
    const userId = (jwtDecode(session) as any).userId;
    if(userId) setErrorUserId(userId);

    return <SignatureModule session={session} />;
  }

  return (
    <FullScreenContainer>
      <Icon className="icon-cancel-circled" size={8} color={DigitalSignatureTheme.errorIcon} />
      <Text type="header-3" style={{ paddingBottom: '16px' }}>It seems like your link is invalid!</Text>
      <Text type="caption">Please go back to your account settings in Diwala platform and request to add a new signature.</Text>
    </FullScreenContainer>
  );
};

export default Signature;
