import isEmpty from 'lodash/isEmpty';

export enum ErrorTypes {
  General,
  Function,
  Server,
};

const getProperty = (error: any, type: ErrorTypes, property: any) => {
  if (type === ErrorTypes.Server) {
    const data = error.response ? error.response.data : null;
    const propertyValue = data ? data[property] : null;
    if (propertyValue) {
      return propertyValue;
    }
    return error[property];
  }
  return error[property];
};

const setMessage = (error: any, type: ErrorTypes) => {
  if (error instanceof Error) {
    return getProperty(error, type, 'message');
  }
  return error;
};

const setStatus = (error: any, type: ErrorTypes) => {
  if (error instanceof Error) {
    return getProperty(error, type, 'status');
  }
  return null;
};

class CustomError extends Error {
  type: ErrorTypes;
  status: string | undefined;

  constructor(type = ErrorTypes.General, ...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.type = type;
    const errData = params[0];
    if (!isEmpty(errData)) {
      this.status = errData.status;
      this.message = errData.message;
    }
  }
}

class FunctionError extends CustomError {
  constructor(err: any, ...params: any[]) {
    super(ErrorTypes.Function, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FunctionError);
    }

    this.name = ErrorTypes.Function.toString();
    if (!isEmpty(err))
    {
      this.message = setMessage(err, this.type);
      this.status = setStatus(err, this.type);
    }
  }
}

class ServerError extends CustomError {
  constructor(err: any, ...params: any[]) {
    super(ErrorTypes.Server, ...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    this.name = ErrorTypes.Server.toString();
    if (!isEmpty(err))
    {
      this.message = setMessage(err, this.type);
      this.status = setStatus(err, this.type);
    }
  }
}

export {
  CustomError,
  FunctionError,
  ServerError
}
