import React from 'react';
import nativeStyled from '@emotion/native';
import { Text as BaseText, textColors } from '@diwala/killer-shrew';

const Wrapper = nativeStyled.View`
  height: 100vh;
  width: 100vw;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Text = nativeStyled(BaseText)`
  color: ${textColors.warning};
  display: block;
  padding-bottom: 8px;
  text-align: center;
`;

export const FallbackUI = () => {
  return (
    <Wrapper>
      <Text type="header-3">It seems like there's a problem, please try reloading the page or go back to your account settings in Diwala platform and request to add a new signature.</Text>
    </Wrapper>
  )
}
