import React from 'react'
import styled from '@emotion/styled';
import { DigitalSignatureTheme } from '@diwala/killer-shrew';

import logo from '../assets/images/diwala_logo.svg'

const Layout = styled.div`
  align-items: center;
  bottom: 0px;
  background-color: ${DigitalSignatureTheme.controller.background};
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
  display: flex;
  height: 48px;
  left: 50%;
  margin: 0 auto;
  max-height: 48px;
  padding: 8px;
  position: absolute;
  transform: translateX(-50%);

  > * {
    padding-left: 12px;
    padding-right: 12px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const Logo = styled.img`
  width: 84px;
`;

export const ControllerGroup: React.FC = ({ children, }) => (
  <Layout>
    <Logo src={logo} />
    {children}
  </Layout>
);
 