import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from '@emotion/styled';
import { WebButton, Icon, DigitalSignatureTheme } from '@diwala/killer-shrew';

import { DigitalSignatureAPI } from 'api';

import { ControllerGroup } from 'components/controller';
import Introduction from 'components/introduction';
import FullScreenContainer from 'components/full-screen-container';
import { Text } from 'components/text';

import URLToFile from 'utils/urltofile';

import { pushError } from 'services/error/error-logger';

interface SignatureComp {
  session: string;
}

const Container = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  background-color: grey;
`;

const ArrowContainer = styled.div`
  display: flex;
  > * {
    padding-left: 7px;
    padding-right: 7px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const calculateHeightAndWidth = ({width, height}: {width: any, height: any}) => {
  // Size of box: 399/120 = 3.325
  const heightRatio = 3.325

  let proportionalWidth = `${width}px`;
  let proportionalHeigh = `${width/heightRatio}px`;
  return {width: proportionalWidth, height: proportionalHeigh}
}

const Signature = ({ session }: SignatureComp) => {
  const [showIntroduction, setShowIntroduction] = useState(true);
  const [hasSavedSuccessfully, setSavedSuccessfully] = useState(false);
  const [hasSaveFailed, setSavedFailed] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentHistoryPosition, setCurrentHistoryPosition] = useState<number>(-1);
  const signatureCavasRef: any = useRef({});

  const canUndo = () => currentHistoryPosition > -1;

  const canRedo = () => currentHistoryPosition < history.length - 1;

  const onSignatureUpdate = () => {
    const signatureData = signatureCavasRef.current.toData();
    setHistory(signatureData);
    setCurrentHistoryPosition(signatureData.length - 1);
  };

  const undo = () => {
    if (canUndo()) {
      signatureCavasRef.current.fromData(history.slice(0, currentHistoryPosition));
      setCurrentHistoryPosition(currentHistoryPosition - 1);
    }
  };

  const redo = () => {
    if (canRedo()) {
      const position = currentHistoryPosition + 1;
      signatureCavasRef.current.fromData(history.slice(0, position + 1));
      setCurrentHistoryPosition(position);
    }
  };

  const clear = () => {
    setHistory([]);
    setCurrentHistoryPosition(-1);
    signatureCavasRef.current.clear();
  };

  const save = async () => {
    try {
      setSaving(true);
      const formData = new FormData();
      const signatureFile = await URLToFile(signatureCavasRef.current.toDataURL('image/svg+xml'), 'signature.svg', 'image/svg+xml');
      formData.append('token', session);
      formData.append('signature', signatureFile);
      await DigitalSignatureAPI.saveSignature(formData);
      setSavedSuccessfully(true);
    } catch (error) {
      setSavedFailed(true);
      pushError(error);
    } finally {
      setSaving(false);
    }
  };

  if (hasSavedSuccessfully) {
    return (
      <FullScreenContainer>
        <Icon className="icon-ok-circled" size={8} color={DigitalSignatureTheme.successIcon} />
        <Text type="header-3" style={{ paddingBottom: '16px' }}>We have saved your signature!</Text>
        <Text type="caption">You can now close this window and go to your account settings in Diwala platform to view the saved signature.</Text>
      </FullScreenContainer>
    );
  }

  if (hasSaveFailed) {
    return (
      <FullScreenContainer>
        <Icon className="icon-cancel-circled" size={8} color={DigitalSignatureTheme.errorIcon} />
        <Text type="header-3" style={{ paddingBottom: '16px' }}>Something went wrong!</Text>
        <Text type="caption">Please try again using 'Add signature' option in Diwala platform's account settings.</Text>
      </FullScreenContainer>
    );
  }

  return (
    <Container>
      <SignatureCanvas
        canvasProps={{ style: { ...calculateHeightAndWidth(window.screen), backgroundColor: 'white' } }}
        ref={signatureCavasRef}
        onEnd={onSignatureUpdate}
      />
      {showIntroduction ? <Introduction onClick={() => setShowIntroduction(false)} /> : null}
      <ControllerGroup>
        <ArrowContainer>
          <WebButton inverted buttonSize="small" type="icon-only" iconName="arrow-left-circle" iconSize={12} onPress={undo} disabled={!canUndo()} />
          <WebButton inverted buttonSize="small" type="icon-only" iconName="arrow-right-circle" iconSize={12} onPress={redo} disabled={!canRedo()} />
        </ArrowContainer>
        <WebButton title="Clear" inverted buttonSize="small" onPress={clear} />
        <WebButton title="Save" buttonSize="small" onPress={save} loading={isSaving} disabled={!canUndo()} />
      </ControllerGroup>
    </Container>
  );
};

export default Signature;
