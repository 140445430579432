import { FunctionError } from 'services/error/error-factory';

/**
 * Render the SVG into DOM and trim whitespace
 *
 * @param url Base64 SVG string
 */
const trimSVG = (url: string) => {
  const svgDataMeta = 'data:image/svg+xml;base64,';

  const signatureRenderer = document.getElementById('signatureRenderer');
  if (signatureRenderer) {
    const decodedSVG = atob(url.split(svgDataMeta)[1]);
    signatureRenderer.innerHTML = decodedSVG;
    const svg = signatureRenderer.getElementsByTagName('svg')[0];
    const bbox = svg.getBBox();

    const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');

    svg.setAttribute('viewBox', viewBox);
    return `${svgDataMeta}${btoa(svg.outerHTML)}`;
  }

  throw new Error('Unable to render SVG');
};

/**
 * Convert Base64 string into a file blob
 *
 * @param url Base64 SVG string
 * @param fileName
 * @param mimeType
 */
const urlToFile = async (url: string, fileName: string, mimeType: string) => {
  try{
    const res = await fetch(trimSVG(url));
    const arrayBuffer = await res.arrayBuffer();
    return new File([arrayBuffer], fileName, { type: mimeType });
  }
  catch(error) {
    throw new FunctionError(error);
  }
};

export default urlToFile;
