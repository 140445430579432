import React from 'react';
import { Fonts } from '@diwala/killer-shrew';

import Signature from './layouts/signature';

const App = () => (
  <>
    <Fonts />
    <Signature />
  </>
);

export default App;
