import RH from './request-handler';
import { ServerError } from 'services/error/error-factory';

export const saveSignature = async (payload: FormData) => {
  try{
    return await RH.post('/digital-signature', payload);
  }
  catch(error) {
    throw new ServerError(error);
  }
}
