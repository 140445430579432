import * as Sentry from '@sentry/react';
import { version } from '../../../package.json';

const SENTRY_DNS: string | undefined = process.env.REACT_APP_SENTRY_DNS;

if(SENTRY_DNS) {
  Sentry.init(
    {
      release: version,
      dsn: SENTRY_DNS
    }
  );
}

let userId: string = '';

const setErrorUserId = (id: string) => {
  userId = id;
}

const pushError = (error: Error, context?: string) => {
  if (SENTRY_DNS) {
    errorLogWithContext(context? context : error.message, error);
  }
  else {
    console.error(error);
  }
};

const errorLogWithContext = (contextMessage: string, error: any) => {
  Sentry.withScope(scope => {
    scope.setTag('tag', error.type);
    scope.setLevel(Sentry.Severity.Error);
    scope.setExtra('Context', contextMessage);
    scope.setUser({id: userId});
    Sentry.captureException(error);
  });
}

const logAppState = (message: string, state: any) => {
  // manipulate logging state to remove critical information
  let logState = state;
  logState.userdid = state.user ? state.user.did : '';
  delete logState.token;
  delete logState.entity;
  delete logState.user;

  Sentry.withScope(scope => {
    scope.setTag('tag', 'App state');
    scope.setLevel(Sentry.Severity.Info);
    scope.setExtra('data', logState);
    Sentry.captureMessage(message);
  });
}

export { pushError, logAppState, setErrorUserId};
